<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form
                  class="form"
                  @submit.stop.prevent="formOnsubmit()"
                  v-if="dataLoaded"
                >
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      Edit Kategori Pengumuman
                    </h3>
                    <b-form-group
                      id="input-group-name"
                      label="Nama:"
                      label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Nama Kategori Pengumuman"
                      ></b-form-input>
                      <small class="text-danger">{{ error.name }}</small>
                    </b-form-group>

                    <!-- Description input -->
                    <b-form-group id="input-group-description">
                      <label for="input-description"
                        >Deskripsi: <em class="text-muted">opsional</em></label
                      >
                      <b-form-textarea
                        id="input-description"
                        v-model="form.description"
                        placeholder="Deskripsi Kategori Pengumuman"
                        rows="4"
                        max-rows="8"
                      ></b-form-textarea>
                      <small class="text-danger">{{ error.description }}</small>
                    </b-form-group>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="
                          $router.push('/masters/announcement-categories')
                        "
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
      },
      error: {
        name: "",
        description: "",
      },
      dataLoaded: false,
    };
  },
  methods: {
    async get() {
      this.form = await module.get(
        "api/announcement-categories/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/announcement-categories");
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "api/announcement-categories/" + this.$route.params.id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/masters/announcement-categories");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      {
        title: "Kategori Pengumuman",
        route: "/masters/announcement-categories",
      },
      { title: "Edit Kategori Pengumuman" },
    ]);

    this.get();
  },
};
</script>